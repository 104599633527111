import React from "react";
import Navigation from "../Navigation/Navbar";

export default function Header(props) {

  return (
    <div>
      <header>
        <Navigation />

      </header>
      <main>

      </main>
    </div >
  );
}